import React from "react";
import {
  Layout,
  Section,
  SEO,
  Content,
  Counter,
  Title,
  AnimTitle,
  Footer
} from "components";
import styles from "./index.module.scss";
import twitter from "./twitter.png";
import facebook from "./facebook.png";

function IndexPage({ ...props }) {
  return (
    <Layout>
      <SEO
        title="LIVE nedskæringstæller: Følg regeringens nedskæringer her"
        description="Regeringen har allerede skåret 6 milliarder på uddannelse siden 2015. Følg nedskæringerne live her."
      />
      <Section gradient>
        <Content>
          <Title as="h1" large>
            <AnimTitle
              bold
              fadeText="Siden 2015 har regeringen skåret ned"
              typewriterText="på uddannelse med over 6 milliarder kroner:"
            />
          </Title>
          <Counter />
          <Title as="h3" narrow>
            <AnimTitle
              delay={6}
              typewriterText="Og nedskæringerne fortsætter."
            />
            <br />
            <AnimTitle
              delay={7.5}
              typewriterText="Hver dag bliver der skåret over 9 millioner kr.*"
            />
          </Title>
          <div className={styles.share}>
            <a
              href="https://www.facebook.com/sharer/sharer.php?u=www.nedskaeringer.dk&quote=Regeringen har allerede skåret 6 milliarder på uddannelse siden 2015. Følg nedskæringerne live her."
              target="_blank"
            >
              <img src={facebook} alt="Del på facebook" />
            </a>
            <a
              href="https://twitter.com/home?status=Regeringen har allerede skåret 6 milliarder på uddannelse siden 2015 - og nedskæringerne fortsætter. Følg nedskæringerne live på www.nedskaeringer.dk #dkpol #stop2pctNU #uddpol"
              target="_blank"
            >
              <img src={twitter} alt="Del på Twitter" />
            </a>
          </div>
        </Content>
        <div className={styles.arrow_cta}>
          <a href="#video">
            <img
              className={styles.arrow_cta_icon}
              src="/arrow-right.svg"
              alt="Fagbevægelsens Hovedorganisation"
            />
          </a>
        </div>
      </Section>
      <Section white id="video">
        <Title as="h2" large red>
          <strong>Der er noget galt i Danmark</strong>
          <br />
          <span>...når vi skærer på uddannelse.</span>
        </Title>
        <Content>
          <div className={styles.video}>
            <iframe
              src="https://www.youtube.com/embed/sF2ueVuUeVE"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </Content>
      </Section>
      <Section red>
        <Title as="h2" large>
          <strong>
            *Derfor rammer nedskæringerne
            <br />
            uddannelserne hårdt
          </strong>
        </Title>
        <Content>
          <div className={styles.flex}>
            <p className={styles.lead}>
              Mange uddannelser bliver tvunget til at skære 2 pct. på grund af
              regeringens sparekrav – hvert år.
            </p>
            <p className={styles.lead}>
              2 pct. lyder måske ikke af så meget, men når det fortsætter over
              flere år, bliver det til en nedskæring i milliardklassen.
            </p>
            <p className={styles.lead}>
              Nedskæringstælleren viser, hvor meget der er skåret på
              ungdomsuddannelser og videregående uddannelser siden 2015 til nu.
              Den tæller op, hvor meget mere der bliver skåret for hvert sekund,
              der går. Det løber op i 9 mio. kr. om dagen sammenlignet med
              bevillingsniveauet i 2015.
            </p>
            <p className={styles.lead}>
              Hvis nedskæringerne fortsætter, når tælleren op på over 20
              milliarder kroner i 2022.
            </p>
            <p className={styles.lead}>
              I fagbevægelsen er vi imod nedskæringer på uddannelse. Vi bør i
              stedet investere i Danmarks fremtid.
            </p>
          </div>
          <h2>
            <strong>Stop uligheden. Styrk velfærden.</strong>
          </h2>
        </Content>
      </Section>
      <Footer />
    </Layout>
  );
}

export default IndexPage;
